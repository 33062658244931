<template>
  <div class="risk-gauge my-2">
    <b-container fluid>
      <!-- Threshold labels -->
      <b-row no-gutters>
        <b-col cols="4" lg="3" class="small text-right">
          10<sup>{{ negligibleLevel | sciExp }}</sup>
        </b-col>

        <b-col cols="4" lg="6"> </b-col>

        <b-col cols="4" lg="3" class="small text-left">
          10<sup>{{ intolerableLevel | sciExp }}</sup>
        </b-col>
      </b-row>

      <!-- Coloured Bar -->
      <b-row no-gutters>
        <b-col
          cols="4"
          lg="3"
          class="negligible text-nowrap"
          :class="{ inactive: !riskIsNegligible }"
        >
          <div
            v-if="riskIsNegligible && validValue"
            class="section-outline highlight-outline"
          >
            Negligible
          </div>
        </b-col>

        <b-col
          cols="4"
          lg="6"
          class="intermediate text-nowrap"
          :class="{ inactive: !riskIsIntermediate }"
        >
          <div
            v-if="riskIsIntermediate"
            class="section-outline highlight-outline"
          >
            ALARP
          </div>
        </b-col>

        <b-col
          cols="4"
          lg="3"
          class="intolerable text-nowrap"
          :class="{ inactive: !riskIsIntolerable }"
        >
          <div
            v-if="riskIsIntolerable"
            class="section-outline highlight-outline"
          >
            Intolerable
          </div>
        </b-col>
      </b-row>

      <!-- Probability Labels -->
      <b-row no-gutters>
        <b-col cols="4" lg="3" class="text-center small">
          <div
            v-if="riskIsNegligible && validValue"
            class="risk-gauge-label mt-2"
          >
            {{ label }} &asymp; {{ value | sciFrac(1) }}&times;10<sup>{{
              value | sciExp(1)
            }}</sup>
          </div>
        </b-col>

        <b-col cols="4" lg="6" class="text-center small">
          <div v-if="riskIsIntermediate" class="risk-gauge-label mt-2">
            {{ label }} &asymp; {{ value | sciFrac(1) }}&times;10<sup>{{
              value | sciExp(1)
            }}</sup>
          </div>
          <div v-if="!validValue">Calculating...</div>
        </b-col>

        <b-col cols="4" lg="3" class="text-center small">
          <div v-if="riskIsIntolerable" class="risk-gauge-label mt-2">
            {{ label }} &asymp; {{ value | sciFrac(1) }}&times;10<sup>{{
              value | sciExp
            }}</sup>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'RiskGauge',
  props: {
    intolerableLevel: {
      default: 1e-3,
      type: Number,
    },
    negligibleLevel: {
      default: 1e-6,
      type: Number,
    },
    value: {
      default: 0,
      type: Number,
    },
    label: {
      default: 'p',
      type: String,
    },
    validValue: {
      default: true,
      type: Boolean,
    },
  },
  data: function () {
    return {
      colorNegligible: 'green',
      colorIntolerable: 'red',
      colorMinIntermediate: 'yellow',
    }
  },
  computed: {
    riskIsNegligible: function () {
      return this.value <= this.negligibleLevel
    },
    riskIsIntolerable: function () {
      return this.value >= this.intolerableLevel
    },
    riskIsIntermediate: function () {
      return !this.riskIsNegligible && !this.riskIsIntolerable
    },
  },
}
</script>

<style lang="scss" scoped>
$neg: #00a71b;
$intol: red;
$minInter: yellow;
$maxIntoler: orange;
// $highlight-color: rgb(255, 23, 23);
// $highlight-color: rgba(0, 89, 145, 0.75);
$highlight-color: rgba(0, 0, 0, 0.75);

$active-border-width: 6px;
$active-border-style: solid;

$bar-height: 2.5rem;
$bar-radius: 5px;

$z-bar: 5;
$z-highlight: 10;

$inactive-saturation: 0.1;

.negligible {
  background-color: $neg;
  outline-color: purple;
  height: $bar-height;
  z-index: $z-bar;
  border-top-left-radius: $bar-radius;
  border-bottom-left-radius: $bar-radius;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  // box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
}

.intolerable {
  background: $intol;
  outline-color: darkred;
  height: $bar-height;
  z-index: $z-bar;
  border-top-right-radius: $bar-radius;
  border-bottom-right-radius: $bar-radius;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  // box-shadow: 0px 0px 2px -2px rgba(0, 0, 0, 0.75);
}

.intermediate {
  background: linear-gradient(to right, $minInter, $maxIntoler);
  // background: linear-gradient(to right, $neg, $minInter 1%, $maxIntoler 99%, $intol);
  outline-color: pink;
  height: $bar-height;
  z-index: $z-bar;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  // box-shadow: 0px 0px 2px -2px rgba(0, 0, 0, 0.75);
}

.active-label {
  outline-width: $active-border-width;
  //outline-style: $active-border-style;
  text-align: center;
  vertical-align: center;
  font-weight: bold;
}

.inactive {
  // filter: saturate($inactive-saturation);
  filter: opacity(0.25);
}

.highlight-outline {
  // outline-style: solid;
  // outline-width: 3px;
  // outline-offset: 2px;
  // outline-color: rgb(138, 162, 190);

  // display: flex;
  // z-index: $z-highlight;

  border-radius: inherit;
  // inset shadow to avoid clipping on RHS
  //box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75) inset;

  box-shadow: 0px 10px 10px -5px $highlight-color,
    0px -5px 10px -5px $highlight-color;
  // box-shadow: 0px 5px 5px -3px $highlight-color, 0px -3px 5px -3px $highlight-color;
}

.section-outline {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: $bar-height;
  font-weight: bold;
  // color: white;
}

.risk-gauge-label {
  white-space: nowrap;
}
</style>
