<template>
  <section-b-card cardHeader="Hazard Scenario">
    <div slot="rightHeader" v-if="calculating">
      <font-awesome-icon icon="spinner" class="fa-spin" />
    </div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" xl="7" class="px-0">
          <b-form-group
            label="Shock Path"
            label-for="voltage-path-list"
            label-cols="4"
            label-cols-xl="5"
            id="voltage-path-form-group"
            class="mb-2"
          >
            <b-form-select
              :disabled="!enabled"
              id="voltage-path-list"
              v-model="voltagePath"
              :options="voltagePathEnum"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" xl="5" class="px-0">
          <b-form-group
            label="Body"
            label-for="footwear-condition-list"
            label-cols="4"
            id="footwear-condition-form-group"
            class="mb-2"
          >
            <b-form-select
              :disabled="!enabled"
              id="footwear-condition-list"
              v-model="footwearCondition"
              :options="footwearConditionEnum"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" xl="7" class="px-0">
          <b-form-group
            label="Soil Resistivity"
            label-for="resistivity-input"
            label-cols="4"
            label-cols-xl="5"
            class="mb-2"
          >
            <b-form-select
              :disabled="!enabled"
              id="soil-res-list"
              v-model="resistivity"
              :options="soilResEnum"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" xl="5" class="px-0">
          <surface-treatment :enabled="enabled"></surface-treatment>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" xl="12" class="px-0">
          <b-form-group
            label="Footwear"
            label-for="footwear-type-list"
            label-cols="4"
            label-cols-xl="3"
            id="footwear-type-form-group"
            class="mb-1"
          >
            <b-form-select
              :disabled="!enabled"
              id="footwear-type-list"
              v-model="footwear"
              :options="footwearTypeEnum"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <!-- Pop-up for unsupported soil res -->
    <b-modal centered title="Soil Resistivity Changed" ref="resChangedModal">
      <p class="my-4">
        The soil resistivity you have specified is not valid for the selected
        Footwear type. Changing to 50&#8486;m
      </p>
    </b-modal>
    <!-- Pop-up for unsupported soil res (wet) -->
    <b-modal centered title="Footwear Type Changed" ref="footwearChangedModal">
      <p class="my-4">
        The Footwear type you have specified is not valid for wet conditions.
        Changing to Barefoot
      </p>
    </b-modal>
    <!-- Pop-up for warning about gumboots only being for touch voltages -->
    <b-modal centered title="Shock Path Changed" ref="pathChangedModal">
      <p class="my-4">
        Only Touch Voltage calculations are supported with Safety Gumboots
        footwear data. Changing to Touch Voltage Shock Path.
      </p>
    </b-modal>

    <!-- Pop-up for unsupported soil res for Surface -->
    <b-modal centered title="Soil Resistivity Changed" ref="resChangedModal2">
      <p class="my-4">
        The soil resistivity you have specified is not valid for the selected
        Surface type. Changing to 50&#8486;m
      </p>
    </b-modal>
  </section-b-card>
</template>

<script>
import SurfaceTreatment from '@/components/SurfaceTreatment.vue'
import SectionBCard from '@/components/SectionBCard.vue'
import _ from 'lodash'

export default {
  name: 'FibrillationProbability',
  components: {
    SurfaceTreatment,
    SectionBCard,
  },
  props: {
    enabled: {},
  },
  computed: {
    voltagePath: {
      get() {
        return this.$store.state.calculations.inputData.fibrillationData
          .voltagePath
      },
      set(value) {
        this.$store.commit('calculations/inputData/setVoltagePath', value)
      },
    },
    footwearCondition: {
      get() {
        return this.$store.state.calculations.inputData.fibrillationData
          .footwearCondition
      },
      set(value) {
        this.$store.commit('calculations/inputData/setFootwearCondition', value)
      },
    },
    resistivity: {
      get() {
        return this.$store.state.calculations.inputData.fibrillationData
          .resistivity
      },
      set(value) {
        this.$store.commit('calculations/inputData/setResistivity', value)
      },
    },
    footwear: {
      get() {
        return this.$store.state.calculations.inputData.fibrillationData
          .footwear
      },
      set(value) {
        this.$store.commit('calculations/inputData/setFootwear', value)
      },
    },
    surface: {
      get() {
        return this.$store.state.calculations.inputData.fibrillationData.surface
      },
    },
    calculating: {
      get() {
        return this.$store.state.calculations.calcStatus.pFibCalculating
      },
    },
    soilResEnum: function () {
      // For surface layer, only 5 and 50 Ωm are available
      if (this.surface !== 'None') {
        return [
          { value: 5, text: ' 5 Ωm' },
          { value: 50, text: '50 Ωm' },
        ]
      }

      switch (this.footwear) {
        case 'Barefoot':
        case 'AS2067_SafetyGumboots':
          return [
            { value: 5, text: ' 5 Ωm' },
            { value: 50, text: '50 Ωm' },
          ]
        case 'EG0_Electrical':
        case 'EG0_Standard':
        default:
          return [
            { value: 5, text: '   5 Ωm' },
            { value: 50, text: '  50 Ωm' },
            { value: 100, text: ' 100 Ωm' },
            { value: 200, text: ' 200 Ωm' },
            { value: 500, text: ' 500 Ωm' },
            { value: 1000, text: '1000 Ωm' },
            { value: 2000, text: '2000 Ωm' },
            { value: 5000, text: '5000 Ωm' },
          ]
      }
    },
    footwearTypeEnum: function () {
      switch (this.footwearCondition) {
        case 'Wet':
          return [{ value: 'Barefoot', text: 'Barefoot', description: '' }]
        default:
          return [
            { value: 'Barefoot', text: 'Barefoot', description: '' },
            {
              value: 'EG0_Electrical',
              text: 'Electrical (EG-0)',
              description: '',
            },
            { value: 'EG0_Standard', text: 'Standard (EG-0)', description: '' },
            {
              value: 'AS2067_SafetyGumboots',
              text: 'Safety Gumboots (AS-2067)',
              description: '',
            },
          ]
      }
    },
    voltagePathEnum: function () {
      switch (this.footwear) {
        case 'Barefoot':
          return [
            { value: 'Touch', text: 'Touch', description: '' },
            { value: 'Step', text: 'Step', description: '' },
          ]
        case 'EG0_Electrical':
        case 'EG0_Standard':
          return [
            { value: 'Touch', text: 'Touch', description: '' },
            { value: 'Step', text: 'Step', description: '' },
            { value: 'HandToHand', text: 'Hand To Hand', description: '' },
          ]
        case 'AS2067_SafetyGumboots':
          return [{ value: 'Touch', text: 'Touch', description: '' }]
        default:
          return []
      }
    },
  },
  data: function () {
    return {
      showAutosetVoltage: false,
      // voltagePathEnum: [
      //   { value: 'Touch', text: 'Touch', description: '' },
      //   { value: 'Step', text: 'Step', description: '' },
      //   { value: 'HandToHand', text: 'Hand To Hand', description: '' }
      // ],
      // footwearTypeEnum: [
      //   { value: 'Barefoot', text: 'Barefoot', description: '' },
      //   { value: 'EG0_Electrical', text: 'Electrical (EG-0)', description: '' },
      //   { value: 'EG0_Standard', text: 'Standard (EG-0)', description: '' }
      //   // {value: 'AS2067SafetyGumboots', text: 'Safety Gumboots (AS-2067)', description: ''}
      // ],
      footwearConditionEnum: [
        { value: 'Dry', text: 'Dry', description: '' },
        { value: 'Wet', text: 'Wet', description: '' },
      ],
    }
  },
  watch: {
    footwear: function () {
      switch (this.footwear) {
        case 'Barefoot':
        case 'AS2067_SafetyGumboots':
          if (!_.find(this.soilResEnum, { value: this.resistivity })) {
            this.resistivity = 50
            this.showResChangedModal()
          }
          break
        case 'EG0_Electrical':
          break
        case 'EG0_Standard':
          break
      }
    },
    footwearCondition: function () {
      if (this.footwearCondition === 'Wet') {
        if (this.footwear !== 'Barefoot') {
          this.footwear = 'Barefoot'
          this.showFootwearChangedModal()
        }
      }
    },
    voltagePath: function () {
      if (this.footwear === 'AS2067_SafetyGumboots') {
        if (this.voltagePath !== 'Touch') {
          this.voltagePath = 'Touch'
          this.showPathChangedModal()
        }
      }
    },
    surface: function () {
      // console.log('Watching surface')
      // console.log(this.surface)
      if (this.surface !== 'None') {
        if (!_.find(this.soilResEnum, { value: this.resistivity })) {
          // console.log(this.resistivity)
          this.resistivity = 50
          this.showResChangedModal2()
        }
      }
    },
  },
  methods: {
    showResChangedModal() {
      this.$refs.resChangedModal.show()
    },
    showResChangedModal2() {
      this.$refs.resChangedModal2.show()
    },
    showFootwearChangedModal() {
      this.$refs.footwearChangedModal.show()
    },
    showPathChangedModal() {
      this.$refs.pathChangedModal.show()
    },
  },
}
</script>

<style></style>
