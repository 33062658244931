<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="px-2">
          <hazard-data :enabled="enabled"></hazard-data>
        </b-col>
      </b-row>
      <hr />
      <b-row class="align-items-center">
        <b-col cols="6" lg="3" class="text-left text-lg-right font-weight-bold">
          <span>Probability of Fibrillation</span>
        </b-col>

        <b-col
          cols="6"
          lg="2"
          class="text-right text-lg-left font-weight-bold text-nowrap"
        >
          <font-awesome-icon
            v-if="calculating"
            icon="spinner"
            class="fa-spin"
          />
          <div v-else>
            {{ pFib | sciFrac }}&times;10
            <sup>
              {{ pFib | sciExp }}
            </sup>
          </div>
        </b-col>

        <b-col cols="12" lg="7">
          <risk-gauge
            :value="pFib"
            :negligibleLevel="negligibleLevel"
            :intolerableLevel="intolerableLevel"
            :validValue="!calculating"
            label="P(fib.)"
          >
          </risk-gauge>
        </b-col>
      </b-row>
      <hr />
      <b-row class="align-items-center">
        <b-col cols="6" lg="3" class="text-left text-lg-right font-weight-bold">
          <span>Probability of Coincidence</span>
        </b-col>

        <b-col
          cols="6"
          lg="2"
          class="text-right text-lg-left font-weight-bold text-nowrap"
        >
          <font-awesome-icon
            v-if="calculating"
            icon="spinner"
            class="fa-spin"
          />
          <div v-else>
            {{ this.probabilityOfCoincidence | sciFrac }}
            &times;10<sup>
              {{ this.probabilityOfCoincidence | sciExp }}
            </sup>
          </div>
        </b-col>
        <b-col cols="12" lg="7">
          <b-card
            sub-title="Exposure Component Details"
            class="text-center font-italic"
            border-variant="light"
            v-if="!calculating"
          >
            P(at least one fault) = {{ probabilityOfFault | max4dp }}
            <br />
            P(at least one contact) = {{ probabilityOfContact | max4dp }}
          </b-card>
        </b-col>
      </b-row>
      <hr />
      <b-row class="align-items-center">
        <b-col cols="6" lg="3" class="text-left text-lg-right font-weight-bold">
          <div>Fatality Risk</div>
        </b-col>
        <b-col
          cols="6"
          lg="2"
          class="text-right text-lg-left font-weight-bold text-nowrap"
        >
          <font-awesome-icon
            v-if="calculating"
            icon="spinner"
            class="fa-spin"
          />
          <div v-else>
            {{ pFatal | sciFrac }}&times;10<sup>{{ pFatal | sciExp }}</sup>
          </div>
        </b-col>
        <b-col cols="12" lg="7">
          <risk-gauge
            :value="pFatal"
            :negligibleLevel="negligibleLevel"
            :intolerableLevel="intolerableLevel"
            :validValue="!calculating"
            label="P(fatal.)"
          >
          </risk-gauge>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HazardData from '@/components/HazardData.vue'
// import RiskResults from '@/components/RiskResults.vue'
// import SectionBCard from '@/components/SectionBCard.vue'
import RiskGauge from '@/components/RiskGauge.vue'

export default {
  name: 'HazardAssessmentDisplay',
  components: {
    HazardData,
    // RiskResults,
    // SectionBCard,
    RiskGauge,
  },
  props: {
    enabled: { type: Boolean, default: true },
  },
  computed: {
    negligibleLevel: {
      get() {
        return this.$store.state.calculations.inputData.riskAnalysis
          .negligibleLevel
      },
      set(value) {
        this.$store.commit('calculations/inputData/setNegligibleLevel', value)
      },
    },
    intolerableLevel: {
      get() {
        return this.$store.state.calculations.inputData.riskAnalysis
          .intolerableLevel
      },
      set(value) {
        this.$store.commit('calculations/inputData/setIntolerableLevel', value)
      },
    },
    pFib: {
      get() {
        return this.$store.state.calculations.results.fibrillationData.pFib
      },
    },
    probabilityOfCoincidence: {
      get() {
        return this.$store.state.calculations.results.coincidenceData
          .probabilityOfCoincidence
      },
    },
    probabilityOfFault: {
      get() {
        return this.$store.state.calculations.results.coincidenceData
          .probabilityOfFault
      },
    },
    probabilityOfContact: {
      get() {
        return this.$store.state.calculations.results.coincidenceData
          .probabilityOfContact
      },
    },
    calculating: {
      get() {
        return (
          this.$store.state.calculations.calcStatus.pCoincCalculating ||
          this.$store.state.calculations.calcStatus.pFibCalculating
        )
      },
    },
    pFatal: function () {
      return this.pFib * this.probabilityOfCoincidence
    },
  },
  data: function () {
    return {
      negligibleLevelEnum: [
        { value: 1e-3, text: '1e-3 (1 in 1,000)' },
        { value: 1e-4, text: '1e-4' },
        { value: 1e-5, text: '1e-5' },
        { value: 1e-6, text: '1e-6 (1 in 1,000,000)' },
        { value: 1e-7, text: '1e-7' },
        { value: 1e-8, text: '1e-8' },
        { value: 1e-9, text: '1e-9 (1 in 1,000,000,000)' },
      ],
      intolerableLevelEnum: [
        { value: 1e-1, text: '1e-1 (1 in 10)' },
        { value: 1e-2, text: '1e-2 (1 in 100)' },
        { value: 1e-3, text: '1e-3 (1 in 1,000)' },
        { value: 1e-4, text: '1e-4' },
        { value: 1e-5, text: '1e-5' },
        { value: 1e-6, text: '1e-6 (1 in 1,000,000)' },
      ],
    }
  },
}
</script>

<style></style>
