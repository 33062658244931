<template>
  <div>
    <b-alert dismissible :show="!emailVerified" variant="warning">
      <h4>Email not yet verified</h4>
      <p>
        Your access to this software will expire in
        {{ hoursUntilAccessExpires }} hours unless you verify your email address
        by clicking the link in the welcome email.
      </p>
    </b-alert>
    <!-- Error Messages -->
    <b-container class="pt-2">
      <b-row>
        <b-col cols="12">
          <error-message
            id="pfib-error"
            title="Fibrillation Probability Calculation Failed"
            :show="pFibFailed"
            :message="pFibErrorMessage"
            :errorDetail="pFibErrorDetail"
            @retry="autoUpdatePFib"
          />

          <error-message
            id="pcoinc-error"
            title="Likelihood of Exposure Calculation Failed"
            :show="pCoincFailed"
            :message="pCoincErrorMessage"
            :errorDetail="pCoincErrorDetail"
            @retry="autoUpdateCoincidence"
          />

          <error-message
            id="curve-error"
            title="Design Curve Calculation Failed"
            :show="curveFailed"
            :message="curveErrorMessage"
            :errorDetail="curveErrorDetail"
            @retry="autoUpdateCurve"
          />
        </b-col>
      </b-row>
    </b-container>

    <!-- The Good Stuff -->
    <b-container fluid class="mt-2">
      <b-row>
        <!-- General Input Stuff -->
        <b-col cols="12" md="5" class="px-0">
          <b-container fluid class="px-2">
            <b-row no-gutters>
              <b-col cols="12" class="px-0">
                <fibrillation-probability :enabled="general.inputsEnabled">
                </fibrillation-probability>
              </b-col>

              <b-col cols="12" class="px-0">
                <coincidence-probability :enabled="general.inputsEnabled">
                </coincidence-probability>
              </b-col>

              <b-col cols="12">
                <section-b-card cardHeader="Risk Thresholds">
                  <b-container fluid>
                    <b-row no gutters>
                      <b-col cols="12">
                        <b-form-group
                          label="Negligible"
                          label-for="negligible-probability-list"
                          label-cols="4"
                          id="negligible-probability-form-group"
                          class="mb-1"
                        >
                          <b-form-select
                            id="negligible-probability-list"
                            v-model="negligibleLevel"
                            :options="negligibleLevelEnum"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group
                          label="Intolerable"
                          label-for="intolerable-probability-list"
                          label-cols="4"
                          id="intolerable-probability-form-group"
                          class="mb-1"
                        >
                          <b-form-select
                            id="intolerable-probability-list"
                            v-model="intolerableLevel"
                            :options="intolerableLevelEnum"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-container>
                </section-b-card>
              </b-col>
            </b-row>
          </b-container>
        </b-col>

        <!-- The Output Stuff -->
        <b-col cols="12" md="7" class="px-2">
          <b-card no-body>
            <b-tabs card>
              <b-tab no-body title="Hazard Assessment">
                <hazard-assessment-display :enabled="general.inputsEnabled">
                </hazard-assessment-display>
              </b-tab>

              <b-tab no-body title="Design Curve">
                <new-design-curve :scenario="scenario"> </new-design-curve>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FibrillationProbability from '@/components/FibrillationProbability.vue'
import CoincidenceProbability from '@/components/CoincidenceProbability.vue'
import HazardAssessmentDisplay from '@/components/HazardAssessmentDisplay.vue'
import NewDesignCurve from '@/components/NewDesignCurve.vue'
import SectionBCard from '@/components/SectionBCard.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'

import { debounce } from 'lodash'
export default {
  name: 'NewCalculator',
  components: {
    FibrillationProbability,
    CoincidenceProbability,
    HazardAssessmentDisplay,
    NewDesignCurve,
    SectionBCard,
    ErrorMessage,
  },
  data: function () {
    return {
      general: {
        inputsEnabled: true,
      },
      negligibleLevelEnum: [
        // NOTE: these entries have the "superscripts" done with unicode chars...but they're really small & hard to read
        // { value: 1e-3, text: '10&#8315;&sup3; (1 in 1,000)' },
        // { value: 1e-4, text: '10&#8315;&#8308;' },
        // { value: 1e-5, text: '10&#8315;&#8309;' },
        // { value: 1e-6, text: '10&#8315;&#8310; (1 in 1,000,000)' },
        // { value: 1e-7, text: '10&#8315;&#8311;' },
        // { value: 1e-8, text: '10&#8315;&#8312;' },
        // { value: 1e-9, text: '10&#8315;&#8313; (1 in 1,000,000,000)' }
        { value: 1e-3, text: '1e-3 (1 in 1,000)' },
        { value: 1e-4, text: '1e-4' },
        { value: 1e-5, text: '1e-5' },
        { value: 1e-6, text: '1e-6 (1 in 1,000,000)' },
        { value: 1e-7, text: '1e-7' },
        { value: 1e-8, text: '1e-8' },
        { value: 1e-9, text: '1e-9 (1 in 1,000,000,000)' },
      ],
      intolerableLevelEnum: [
        // NOTE: these entries have the "superscripts" done with unicode chars...but they're really small & hard to read
        // { value: 1e-1, text: '10&#8315;&sup1; (1 in 10)' },
        // { value: 1e-2, text: '10&#8315;&sup2; (1 in 100)' },
        // { value: 1e-3, text: '10&#8315;&sup3; (1 in 1,000)' },
        // { value: 1e-4, text: '10&#8315;&#8308;' },
        // { value: 1e-5, text: '10&#8315;&#8309;' },
        // { value: 1e-6, text: '10&#8315;&#8310; (1 in 1,000,000)' }

        // NOTE: this might be the "more correct" way to show it...but we'll see if more people under stand 10^x instead of 1ex
        { value: 1e-1, text: '1e-1 (1 in 10)' },
        { value: 1e-2, text: '1e-2 (1 in 100)' },
        { value: 1e-3, text: '1e-3 (1 in 1,000)' },
        { value: 1e-4, text: '1e-4 (1 in 10,000)' },
        { value: 1e-5, text: '1e-5' },
        { value: 1e-6, text: '1e-6 (1 in 1,000,000)' },

        // NOTE: I tried this thinking it might be "more obvious" than the 'e' notation... but it looks awful on the screen :(
        // { value: 1e-1, text: '10^-1 (1 in 10)' },
        // { value: 1e-2, text: '10^-2 (1 in 100)' },
        // { value: 1e-3, text: '10^-3 (1 in 1,000)' },
        // { value: 1e-4, text: '10^-4' },
        // { value: 1e-5, text: '10^-5' },
        // { value: 1e-6, text: '10^-6 (1 in 1,000,000)' }
      ],
    }
  },
  mounted: function () {
    this.$bus.$on('apply-data', this.applyCaseStudy)
    this.$bus.$on('recalculate', this.recalculateAll)
    this.autoUpdatePFib()
    this.autoUpdateCoincidence()
    this.autoUpdateCurve()
  },
  computed: {
    negligibleLevel: {
      get() {
        return this.$store.state.calculations.inputData.riskAnalysis
          .negligibleLevel
      },
      set(value) {
        this.$store.commit('calculations/inputData/setNegligibleLevel', value)
      },
    },
    intolerableLevel: {
      get() {
        return this.$store.state.calculations.inputData.riskAnalysis
          .intolerableLevel
      },
      set(value) {
        this.$store.commit('calculations/inputData/setIntolerableLevel', value)
      },
    },
    pCoincFailed: {
      get() {
        return this.$store.state.calculations.calcStatus.pCoincFailed
      },
    },
    pFibFailed: {
      get() {
        return this.$store.state.calculations.calcStatus.pFibFailed
      },
    },
    curveFailed: {
      get() {
        return this.$store.state.calculations.calcStatus.curveFailed
      },
    },
    pCoincErrorMessage: {
      get() {
        return this.$store.state.calculations.calcStatus.pCoincErrorMessage
      },
    },
    pFibErrorMessage: {
      get() {
        return this.$store.state.calculations.calcStatus.pFibErrorMessage
      },
    },
    curveErrorMessage: {
      get() {
        return this.$store.state.calculations.calcStatus.curveErrorMessage
      },
    },
    pCoincErrorDetail: {
      get() {
        return this.$store.state.calculations.calcStatus.pCoincErrorDetail
      },
    },
    pFibErrorDetail: {
      get() {
        return this.$store.state.calculations.calcStatus.pFibErrorDetail
      },
    },
    curveErrorDetail: {
      get() {
        return this.$store.state.calculations.calcStatus.curveErrorDetail
      },
    },
    fibrillationData: {
      get() {
        return this.$store.getters[
          'calculations/inputData/fibrillationInputData'
        ]
      },
    },
    coincidenceData: {
      get() {
        return this.$store.getters[
          'calculations/inputData/coincidenceInputData'
        ]
      },
    },
    maxVTCurveInputData: {
      get() {
        return this.$store.getters['calculations/inputData/maxVTCurveInputData']
      },
    },
    scenario() {
      return {
        hazard: this.fibrillationData,
        exposure: this.coincidenceData,
      }
    },
    emailVerified: {
      // getter
      get: function () {
        // console.log(this.$auth)
        return !this.$auth.gracePeriod
      },
    },
    accessDate: {
      // getter
      get: function () {
        return Date.parse(this.$auth.graceUntil)
      },
    },
    hoursUntilAccessExpires: {
      get: function () {
        var milliseconds = this.accessDate - Date.now()
        return Math.floor(milliseconds / (1000 * 60 * 60))
      },
    },
  },
  watch: {
    fibrillationData: {
      handler() {
        this.clearPFib()
        this.autoUpdatePFib()
      },
      deep: true,
    },
    coincidenceData: {
      handler() {
        this.clearPCoinc()
        this.autoUpdateCoincidence()
      },
      deep: true,
    },
    maxVTCurveInputData: function () {
      this.clearCurveData()
      this.autoUpdateCurve()
    },
    deep: true,
  },
  methods: {
    clearPFib() {
      this.$store.dispatch('calculations/clearPFib')
    },
    clearPCoinc() {
      this.$store.dispatch('calculations/clearPCoinc')
    },
    autoUpdatePFib() {
      this.$store.commit('calculations/calcStatus/setPFibFailed', false)
      this.$store.commit('calculations/calcStatus/setPFibCalculating', true)
      this.debounceCalculatePFib()
    },

    debounceCalculatePFib: debounce(function () {
      this.$store.dispatch('calculations/calculateRisk')
    }, 1500),

    autoUpdateCoincidence() {
      this.$store.commit('calculations/calcStatus/setPCoincFailed', false)
      this.$store.commit('calculations/calcStatus/setPCoincCalculating', true)
      this.debounceCalculateCoincidence()
    },
    debounceCalculateCoincidence: debounce(function () {
      this.$store.dispatch('calculations/calculateCoincidence')
    }, 1500),

    applyCaseStudy: function (caseStudy) {
      this.$store.commit('calculations/inputData/setCaseStudy', caseStudy)
    },

    enableData: function (enableData) {
      this.general.inputsEnabled = enableData
    },
    recalculateAll: debounce(
      function () {
        this.clearPFib()
        this.clearPCoinc()
        this.clearCurveData()
        this.autoUpdatePFib()
        this.autoUpdateCoincidence()
        this.autoUpdateCurve()
      },
      750,
      { leading: true, trailing: false }
    ),

    // Design Curve functions...
    clearCurveData() {
      this.$store.dispatch('calculations/clearCurveData')
    },

    autoUpdateCurve() {
      this.$store.commit('calculations/calcStatus/setCurveFailed', false)
      this.$store.commit('calculations/calcStatus/setCurveCalculating', true)
      this.debounceCalculateCurve()
    },

    debounceCalculateCurve: debounce(function () {
      this.$store.dispatch('calculations/calculateMaxTouchVoltageCurve')
    }, 1500),
  },
}
</script>

<style>
#results {
  text-align: left;
}
</style>
