<template>
  <b-form-group
    label="Surface"
    label-for="surface-treatment-list"
    label-cols="4"
    id="surface-treatment-form-group"
    class="mb-2"
  >
    <b-form-select
      :disabled="!enabled"
      id="surface-treatment-list"
      v-model="surface"
      :options="surfaceTreatmentsEnum"
    />
  </b-form-group>
</template>

<script>
export default {
  components: {},
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    surface: {
      get() {
        return this.$store.state.calculations.inputData.fibrillationData.surface
      },
      set(value) {
        this.$store.commit('calculations/inputData/setSurface', value)
      },
    },
  },
  data: function () {
    return {
      surfaceTreatmentsEnum: [
        {
          value: 'None',
          text: 'Natural',
          description: 'Resistivity=soil res.',
        },
        {
          value: 'EG0_Asphalt',
          text: 'Asphalt (EG-0)',
          description: 'Resistivity=10000, Depth=0.1m, Flashover=3000V',
        },
        {
          value: 'EG0_Crushed_Rock',
          text: 'Crushed Rock (EG-0)',
          description: 'Resistivity=3000, Depth=0.1m',
        },
        {
          value: 'EG0_Concrete',
          text: 'Concrete (EG-0)',
          description: 'Resistivity=3000,Depth=0.1m',
        },
      ],
    }
  },
}
</script>

<style></style>
