<template>
  <section-b-card cardHeader="Exposure Scenario">
    <div slot="rightHeader" v-if="calculating">
      <font-awesome-icon icon="spinner" class="fa-spin" />
    </div>

    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" class="px-0">
          <b-form-group
            label="Fault Rate"
            label-for="fault-frequency-input"
            label-cols="4"
            class="mb-2"
          >
            <b-input-group append="faults/year">
              <b-form-input
                :disabled="!enabled"
                type="number"
                min="0"
                id="fault-frequency-input"
                v-model="faultRate"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" class="px-0">
          <b-container fluid>
            <b-row>
              <b-col class="px-0">
                <b-form-group
                  label="Contact Rate"
                  label-for="contact-rate-input"
                  label-cols="4"
                  class="mb-2"
                >
                  <b-input-group append="touch/year">
                    <b-form-input
                      :disabled="!enabled || useStandardScenario"
                      type="number"
                      min="0"
                      id="contact-rate-input"
                      v-model="contactRate"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" xl="12" class="px-0">
                <b-form-group
                  label="Contact Time"
                  label-for="contact-duration-input"
                  label-cols="4"
                  class="mb-2"
                >
                  <b-input-group append="s">
                    <b-form-input
                      :disabled="!enabled || useStandardScenario"
                      type="number"
                      min="0"
                      id="contact-duration-input"
                      v-model="contactDuration"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </section-b-card>
</template>

<script>
import SectionBCard from '@/components/SectionBCard.vue'

export default {
  name: 'CoincidenceProbability',
  components: {
    SectionBCard,
  },
  props: {
    enabled: {},
  },
  computed: {
    faultRate: {
      get() {
        return this.$store.state.calculations.inputData.coincidenceData
          .faultRate
      },
      set(value) {
        this.$store.commit('calculations/inputData/setFaultRate', value)
      },
    },
    contactRate: {
      get() {
        return this.$store.state.calculations.inputData.coincidenceData
          .contactRate
      },
      set(value) {
        this.$store.commit('calculations/inputData/setContactRate', value)
      },
    },
    contactDuration: {
      get() {
        return this.$store.state.calculations.inputData.coincidenceData
          .contactDuration
      },
      set(value) {
        this.$store.commit('calculations/inputData/setContactDuration', value)
      },
    },
    calculating: {
      get() {
        return this.$store.state.calculations.calcStatus.pCoincCalculating
      },
    },
  },
  data: function () {
    return {
      contactScenario: 'Backyard',
      useStandardScenario: false,
      contactScenarioEnum: [
        {
          value: 'Backyard',
          text: 'Backyard',
          description: '416 contacts for 4s',
          contacts: 416,
          duration: 4,
        },
        {
          value: 'UrbanInterface',
          text: 'Urban Interface',
          description: '100 contacts for 4s',
          contacts: 100,
          duration: 4,
        },
        {
          value: 'Remote',
          text: 'Remote',
          description: '10 contacts for 4s',
          contacts: 10,
          duration: 4,
        },
        {
          value: 'MEN',
          text: 'MEN',
          description: '2000 contacts for 4s',
          contacts: 2000,
          duration: 4,
        },
      ],
    }
  },
  watch: {
    enabled: function () {
      if (this.enabled === false) {
        this.useStandardScenario = false
      }
    },
  },
}
</script>

<style></style>
