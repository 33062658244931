<template>
  <!-- NOTE: adding some top margins on the div after ditching the card -->
  <div class="mt-3">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" xl="6" class="px-0">
          <b-form-group
            label="Voltage"
            label-for="voltage-input"
            label-cols="5"
            class="mb-2"
          >
            <b-input-group append="V">
              <b-form-input
                id="voltage-input"
                :disabled="!enabled"
                type="number"
                min="0"
                v-model="voltage"
                class="pr-0 mr-0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" xl="6" class="px-0">
          <b-form-group
            label="Clearing Time"
            label-for="fib-clearing-time-input"
            label-cols="5"
            class="mb-2"
          >
            <b-input-group append="s">
              <b-form-input
                :disabled="!enabled"
                type="number"
                min="0"
                step="0.1"
                max="10"
                id="fib-clearing-time-input"
                v-model="clearingTime"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import SectionBCard from '@/components/SectionBCard.vue'

export default {
  name: 'HazardData',
  components: {
    // SectionBCard,
  },
  props: {
    enabled: { type: Boolean, default: true },
  },
  computed: {
    voltage: {
      get() {
        return this.$store.state.calculations.inputData.hazardData.voltage
      },
      set(value) {
        this.$store.commit('calculations/inputData/setVoltage', value)
      },
    },
    clearingTime: {
      get() {
        return this.$store.state.calculations.inputData.hazardData.clearingTime
      },
      set(value) {
        this.$store.commit('calculations/inputData/setClearingTime', value)
      },
    },
  },
}
</script>

<style></style>
