<script>
import { Scatter } from 'vue-chartjs'

export default {
  // name: AllowableVoltageChart, //NOTE: for some reason it doesn't work with thi in here...
  extends: Scatter,
  props: {
    // NOTE: don't forget to add a watch if you want the chart to react to prop changes!
    points: {
      default() {
        return []
      },
      type: Array,
    },
    calculating: {
      default: false,
      type: Boolean,
    },
    graphName: {
      default() {
        return 'Maximum Allowable Voltage'
      },
      type: String,
    },
    riskTarget: {
      default() {
        return 1e-6
      },
      type: Number,
    },
    curveLabel: {
      default() {
        return ''
      },
      type: String,
    },
    curveColor: {
      default: '#F37B28',
      type: String,
    },
    voltageLabel: {
      default: '',
      type: String,
    },
  },
  // data: function () {
  //   return {

  //   }
  // },
  computed: {
    titleText() {
      return (
        this.graphName +
        ' for Negligible Risk ( ≈' +
        this.$options.filters.exponential(this.riskTarget, 0) +
        ' )'
      )
    },
    chartOptions() {
      return {
        legend: {
          display: false,
          // labels: {
          //   usePointStyle: true
          // }
        },
        tooltips: {
          mode: 'x',
          intersect: false,
          callbacks: {
            title: function (items) {
              var title = 'Clearing Time = '
              title += items[0].xLabel
              title += 's'
              return title
            },
            label: this.tooltipLabelGenerator,
          },
        },
        title: {
          text: this.titleText,
          display: true,
          fontSize: 16,
        },
        animation: {
          duration: 0, // general animation time
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'logarithmic',
              ticks: {
                autoSkip: false,
                minRotation: 60,
                callback: function (value) {
                  // Bit messy, but the axis was returning things like 0.700000001
                  return Number.parseFloat(value.toFixed(2)).toPrecision()
                },
              },
              scaleLabel: {
                labelString: 'Clearing Time (s)',
                display: true,
              },
              position: 'bottom',
            },
          ],
          yAxes: [
            {
              type: 'logarithmic',
              ticks: {
                autoSkip: false,
                minRotation: 0,
                callback: function (value) {
                  return value.toPrecision()
                },
              },
              scaleLabel: {
                labelString: 'Voltage (V)',
                display: true,
              },
            },
          ],
        },
      }
    },
    chartDatasets() {
      return {
        datasets: [
          {
            label: this.curveLabel,
            borderColor: this.curveColor,
            showLine: true,
            fill: false,
            backgroundColor: this.curveColor,
            cubicInterpolationMode: 'monotone',
            data: this.calculating ? [] : this.points, // NOTE: hack to clear graph when calculating
          },
        ],
      }
    },
  },
  methods: {
    forceChartRefresh() {
      // TODO: we probably want to debounce this call...
      this.renderChart(this.chartDatasets, this.chartOptions)
    },
    tooltipLabelGenerator(tooltipItem, data) {
      var label = data.datasets[tooltipItem.datasetIndex].label || ''

      if (label) {
        label += ': '
      }
      if (this.voltageLabel) {
        label += this.voltageLabel + ' = '
      }

      // label += Math.round(tooltipItem.yLabel * 100) / 100
      label += Math.floor(tooltipItem.yLabel)
      label += 'V'
      return label
    },
  },
  watch: {
    points() {
      this.forceChartRefresh()
    },
    graphName() {
      this.forceChartRefresh()
    },
    riskTarget() {
      this.forceChartRefresh()
    },
    curveLabel() {
      this.forceChartRefresh()
    },
    curveColor() {
      this.forceChartRefresh()
    },
    voltageLabel() {
      this.forceChartRefresh()
    },
    calculating() {
      this.forceChartRefresh()
    },
  },
  mounted() {
    this.forceChartRefresh()
  },
}
</script>

<style></style>
