<template>
  <b-card
    id="section-b-card"
    header-border-variant="secondary"
    border-variant="secondary"
    class="calc-input-card mb-3"
    body-class="pt-2 pb-1 px-2"
    header-class="font-weight-bold text-capitalize"
  >
    <div slot="header">
      <div id="leftHeaderDiv" class="left">
        {{ cardHeader }}
      </div>
      <div id="centreHeaderDiv" align="center">
        <slot name="centreHeader" />
      </div>
      <div id="rightHeaderDiv" class="right">
        <slot name="rightHeader" />
      </div>
    </div>
    <slot></slot>
  </b-card>
</template>

<script>
export default {
  name: 'SectionBCard',
  props: {
    cardHeader: { type: String, default: '' },
  },
}
</script>

<style>
.right {
  float: right;
}
.left {
  float: left;
}
</style>
