<template>
  <div class="design-curve">
    <div class="design-curve-container">
      <allowable-voltage-chart
        class="voltage-design-curve"
        :points="allowablePoints"
        :riskTarget="targetRisk"
        :graphName="graphTitle"
        :voltageLabel="voltageLabel"
        :calculating="calculating"
        curveColor="#F37B28"
      />
    </div>
    <hr class="ml-5 mr-5" />
    <div class="d-flex justify-content-center m-4">
      <b-btn @click="exportChartData" variant="outline-primary"
        >Export Chart Data</b-btn
      >
    </div>
  </div>
</template>

<script>
import AllowableVoltageChart from '@/components/AllowableVoltageChart.vue'
import { saveAs } from 'file-saver/FileSaver'

export default {
  name: 'NewDesignCurve',
  components: {
    AllowableVoltageChart,
  },
  props: {
    scenario: {
      default() {
        return {
          hazard: {},
          exposure: {},
        }
      },
      type: Object,
    },
  },
  computed: {
    targetRisk: {
      get() {
        return this.$store.state.calculations.inputData.riskAnalysis
          .negligibleLevel
      },
      set(value) {
        this.$store.commit('calculations/inputData/setNegligibleLevel', value)
      },
    },
    calculating: {
      get() {
        return this.$store.state.calculations.calcStatus.curveCalculating
      },
    },
    allowablePoints: {
      get() {
        return this.$store.state.calculations.results
          .allowableVoltageCurvePoints
      },
    },
    graphTitle() {
      var pathLabel = 'Voltage'
      // Get a friendly name for the voltage path
      var haz = this.scenario.hazard
      if (haz) {
        var path = haz.voltagePath
        pathLabel = this.voltagePathLabel(path)
      }
      var title = 'Maximum ' + pathLabel

      return title
    },
    voltageLabel() {
      var haz = this.scenario.hazard
      if (haz) {
        var path = haz.voltagePath
        return this.voltagePathAbbreviation(path)
      }
      return ''
    },
  },
  methods: {
    exportChartData: function () {
      // console.log('Save file function')
      var text = this.csvDataset(this.allowablePoints)
      // TODO: we don't need to build a big string...we can just pass in the components in the Array in Blob()
      text += '\r\n'
      text += this.csvAssessmentType()
      text += '\r\n'
      text += this.csvFaultDetails(this.scenario)
      text += '\r\n'
      text += this.csvFibrillationDetails(this.scenario, this.targetRisk)
      text += '\r\n' // NOTE: things like blank lines at the end of files...

      // console.log(text)
      var filename = 'DesignCurve'
      var blob = new Blob([text], { type: 'text/plain' })
      saveAs(blob, filename + '.csv')
    },
    voltagePathAbbreviation(pathEnum) {
      if (pathEnum === 'Touch') {
        return 'Vt'
      } else if (pathEnum === 'Step') {
        return 'Vs'
      } else if (pathEnum === 'HandToHand') {
        return 'Vhh'
      } else {
        return 'Vmax'
      }
    },
    voltagePathLabel(pathEnum) {
      if (pathEnum === 'Touch') {
        return 'Touch Voltage'
      } else if (pathEnum === 'Step') {
        return 'Step Voltage'
      } else if (pathEnum === 'HandToHand') {
        return 'Hand-to-Hand Voltage'
      } else {
        return 'Voltage'
      }
    },
    csvDataset(dataset) {
      var text = 'Time,'
      var i

      for (i = 0; i < dataset.length; i++) {
        text += dataset[i].x
        if (i < dataset.length) {
          text += ', '
        }
      }
      text += '\r\n'
      text += 'Voltage,'
      var j
      for (j = 0; j < dataset.length; j++) {
        text += dataset[j].y
        if (j < dataset.length) {
          text += ', '
        }
      }
      text += '\r\n'
      return text
    },
    csvAssessmentType() {
      return 'Risk Assessment Type, Individual\r\n'
    },
    csvFaultDetails(scenario) {
      var str = 'Fault Assumptions\r\nTimeBase, TimeIndependent\r\n'
      str +=
        'Fault Frequency, ' + scenario.exposure.faultRate + ', per year\r\n'
      str +=
        'Nominal Fault Duration, ' +
        scenario.exposure.clearingTime +
        ', seconds\r\n'
      str += 'Coincidence Reduction Factor, No Coincidence Reduction Used\r\n'
      str += 'Contact Scenario, \r\n' // NOTE: we don't have predefined contact scenarios
      str += 'Contacts per year, ' + scenario.exposure.contactRate + '\r\n'
      str +=
        'Contact Duration, ' +
        scenario.exposure.contactDuration +
        ', seconds\r\n'
      str += '\r\n' // NOTE: we don't have the "nominal coincidence probability here..."
      return str
    },
    csvFibrillationDetails(scenario, riskTarget) {
      var str = 'Fibrillation Settings\r\n'
      str +=
        'Current Path, ' +
        this.voltagePathLabel(scenario.hazard.voltagePath) +
        '\r\n'
      str += 'Footwear Type, ' + scenario.hazard.footwear + '\r\n'
      str += 'Body Impedance, ' + scenario.hazard.footwearCondition + '\r\n'
      str += 'Soil Resistivity, ' + scenario.hazard.resistivity + ', ohm.m\r\n'
      str +=
        'Nominal Hazard Voltage, ' + scenario.hazard.voltage + ', volts\r\n'
      str +=
        'Nominal Fault Duration, ' +
        scenario.exposure.clearingTime +
        ', seconds\r\n'
      str += 'Surface Layer Type, ' + scenario.hazard.surface + '\r\n'
      str += '\r\n\r\n\r\n' // NOTE: we don't have Surface Res, Surface Thickness, Surface Flashover Voltage
      str += '\r\n' // NOTE: we don't have fibrillation probability here...
      str +=
        'Target Probability of Fatality, ' +
        this.$options.filters.exponential(riskTarget, 0) +
        '\r\n'
      str += '\r\n' // NOTE: we're not spitting out a 'risk zone' since we're always targetting negligible...

      return str
    },
  },
}
</script>

<style>
.voltage-design-curve {
  /* position: relative; */
  height: 500px;
  /* width: 400px; */
}
</style>
